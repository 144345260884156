<template>
  <b-card-code>
    <b-row>
      <b-col md="4">
        <b-form-group>
          <b-card-text>
            <span>{{ $t('Select a Child') }} </span>
          </b-card-text>
          <select v-model="student_index" class="form-control"
                  name="classes_id" >
            <option :value="null">Select one</option>
            <option v-for="(student, key) in students" :key="key" :value="key">{{student.cadet_no}} - {{student.userable?student.userable.name:''}}</option>
          </select>
        </b-form-group>
      </b-col>
    </b-row>
    <validation-observer ref="simpleRules">
      <form method="post" action="javascript:void(0)" @submit="validationForm">
        <b-row>
          <div class="col-md-12">
            <center><p style="color:blue">Total Pay: {{total_payable}}</p></center>
          </div>
          <div class="table-responsive">
            <table id="table" class="table table-striped table-bordered" style="width:100%">
              <thead>
              <tr>
                <th>SL</th>
                <th>Fee Type</th>
                <th>Month</th>
                <th>Amount</th>
                <th>Waiver</th>
                <th>Late Fee</th>
                <th>Total Pay</th>
                <th>Due</th>
                <th>Payment Last Date</th>
                <th>Action</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(fee, key) in fees" :key="key">
                <td>{{key+1}}</td>
                <td>{{ studentFeeTypeByID(fee.student_fee_type_id) }}</td>
                <td>{{fee.month_year}}</td>
                <td>Fee Amount: {{fee.amount}}</td>
                <td>
                  <p v-for="(waiver,key2) in fee.waivers" :key="key2">
                    Waiver Type: {{ waiver.student_waiver_type?waiver.student_waiver_type.name:'' }} <br>
                    {{ 'Waiver Amount: ' + waiver.amount }} <br>
                  </p>
                </td>
                <td>
                  <p :style="fee.late_fee_amount===0?'color:green':'color:red'">{{fee.late_fee_amount}}</p>
                </td>
                <td>
                  <p :style="fee.payable===0?'color:green':'color:red'">{{fee.pay}}</p>
                </td>
                <td>
                  <p :style="fee.payable>0?'color:red':'color:green'">{{fee.payable}}</p>
                </td>
                <td>
                  <p :style="fee.payment_last_date >= moment().format('YMD')?'color:green':'color:red'">{{fee.payment_last_date}}</p>
                </td>
                <td>
                  <label class="checkbox checkbox-outline-success" v-if="(fee.payable)>0">
                    <input type="checkbox" @click="addPay(fee)" /><span>pay</span><span class="checkmark"></span>
                  </label>
                </td>
              </tr>
              </tbody>
              <tfoot>
              <tr>
                <th>SL</th>
                <th>Fee Type</th>
                <th>Month</th>
                <th>Amount</th>
                <th>Waiver</th>
                <th>Late Fee</th>
                <th>Total Pay</th>
                <th>Due</th>
                <th>Payment Last Date</th>
                <th>Action</th>
              </tr>
              </tfoot>
            </table>
          </div>
          <div class="col-md-12 justify-content-end">
            <button type="submit" style="float: right" class="btn btn-primary">{{ $t('Pay Now') }}</button>
          </div>
        </b-row>
      </form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BRow,BCol,BFormGroup,BCardText
} from 'bootstrap-vue'
import {  ValidationObserver } from 'vee-validate'
import {required} from '@validations'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
import apiCall from "@/libs/axios";
import moment from "moment"
import {mapGetters} from "vuex";
export default {
  name:'PaymentHistory',
  components: {
    BCardCode,BRow,ValidationObserver,BCol,BFormGroup,BCardText
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      moment,
      required,
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'SL',
          field: 'id',
        },
        {
          label: 'Fee Type',
          field: 'student_fee_type.name',
        },
        {
          label: 'Payment Month',
          field: 'month_year',
        },
        {
          label: 'Amount',
          field: 'amount',
        },
        {
          label: 'Late Fee',
          field: 'late_fee',
        },
        {
          label: 'Payment Last date',
          field: 'payment_last_date',
        },
      ],
      searchTerm: '',
      status:{
        0:'Pending',
        1:'Approved',
        2:'Declined'
      },
      payments:[],
      selected_payments:[],
      students:[],
      selected_student: {},
      student_index:null,

      total_payable:0,
      selected_row:{},
      payment_types:[],
      student_id:null,
      academic_year_id:null,
      student:{},
      fees:[],
      inputs:[],
      is_loading:false,
    }
  },
  methods: {
    async getMaterials(){
      await apiCall.get('/parent/child/assign/fees').then((response)=>{
        this.students=response.data.students;
        this.payments=response.data.payments;
      }).catch(()=>{
        this.students=[];
        this.payments=[];
      })
    },
    findStd(){
      let ss = this.students[this.student_index];
      if(ss) {
        this.selected_student = ss;
        this.student = ss;
      }
      else {
        this.selected_student = {};
        this.student = {};
      }
    },
    findPayments(){
      this.inputs=[];
      this.total_payable=0;
      let ss = this.payments[this.student_index];
      if(ss) this.fees=ss;
      else this.fees=[];
    },
    addPay(fee){
      var index = this.inputs.findIndex(value=> value.assign_student_fee_id === fee.id);
      if (index >=0 ){
        this.removeField(index);
        this.total_payable -=fee.payable;
      }else{
        this.total_payable +=fee.payable;
        this.inputs.push({
              'student_id':this.student.id,
              'version_id':this.student.version_id,
              'classes_id':this.student.classes_id,
              'wing_id':this.student.wing_id,
              'student_group_id':this.student.student_group_id,
              'student_fee_type_id':fee.student_fee_type_id,
              'total_amount':fee.payable,
              'fee_amount':(fee.payable-fee.late_fee_amount)>0?(fee.payable-fee.late_fee_amount):0,
              'late_fee_amount':fee.late_fee_amount>0?(fee.late_fee_amount-fee.payable)>0?fee.payable:fee.late_fee_amount:0,
              'assign_student_fee_id':fee.id,
              'academic_year_id':this.student.academic_year_id,
            }
        );
      }
    },
    async validationForm() {
      this.$refs.simpleRules.validate().then(async success => {
        if (success && !this.is_loading) {
          if (this.inputs.length < 1) return;
          let data = new FormData();
          this.is_loading = true;
          data.append('input', JSON.stringify(this.inputs));
          await apiCall.post('/parent/pay-via-ajax', data).then((response) => {
            this.is_loading = false;
            if (response.data.status === 'success') {
              this.inputs = [];
              this.student = {};
              this.total_payable = 0;
              window.location = response.data.data;
            } else{
              this.error = response.data.message;
            }
          }).catch((error) => {
            if (error.response.status === 422) {
              Object.keys(error.response.data.errors).map((field) => {
                this.$toaster.error(error.response.data.errors[field][0]);
              })
            } else this.$toaster.error(error.response.data.message);
            this.is_loading = false;
          });
        }
      })
    },
    removeField(index){//methods for remove filed
      this.inputs.splice(index, 1)
    },
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    ...mapGetters(['student_fee_types','studentFeeTypeByID',]),
  },
  created() {
      this.getMaterials();
    if(this.student_fee_types.length <1) this.$store.dispatch('GET_ALL_STUDENT_FEE_TYPE');
  },
  watch: {
    student_index(){
      this.findStd();
      this.findPayments();
    }
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>